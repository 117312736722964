import React from "react"
import Layout from "../components/layout"
import {
  websites_Polska,
  websites_Czechy,
  websites_Hiszpania,
  websites_Niemcy,
} from "../util/const"
/** @jsx jsx */
import { jsx, Container, Box, Heading, Text, Grid, Paragraph } from "theme-ui"

const containerStyle = {
  textDecoration: "none",
  color: "text",
  fontSize: 3,
  fontWeight: "bold",
  fontColor: "white",
  display: "block",
  p: 3,
  bg: "primary",
  borderRadius: "8px",
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    color: "primary",
    bg: "highlight",
    transform: "translateY(-2px)",
    boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
  },
}

const CountrySection = ({ title, websites }) => (
  <Box sx={{ mb: 5 }}>
    <Heading as="h2" sx={{ mb: 4, color: "primary" }}>
      {title}
    </Heading>
    <Grid gap={4} columns={[1, 1, 2]}>
      {websites.map((website, index) => (
        <Box key={index}>
          {website.disabled ? (
            <div
              style={{
                ...containerStyle,
                color: "muted",
                height: "70px",
                backgroundColor: "silver",
                display: "flex",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <Text sx={{ color: "muted", fontSize: 2 }}>
                {website.name.replace("Parking", "Lotnisko")} (Wkrótce dostępne)
              </Text>
            </div>
          ) : (
            <a href={`${website.url}`} sx={containerStyle}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "white",
                }}
              >
                {website.slug && (
                  <img
                    src={`/assets/logo.${website.slug}.jpg`}
                    alt={website.name}
                    style={{
                      width: "50px",
                      height: "50px",
                      marginRight: "10px",
                    }}
                  />
                )}
                {website.name.replace("Parking", "Lotnisko")}
              </div>
            </a>
          )}
        </Box>
      ))}
    </Grid>
  </Box>
)

const ListaLotniskPage = () => {
  return (
    <Layout>
      <Box
        sx={{
          position: "relative",
          height: ["200px", "300px", "400px"],
          mb: 4,
          overflow: "hidden",
          // backgroundImage: `url('/assets/hero-airports.jpg')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Heading
            as="h1"
            sx={{
              color: "white",
              textAlign: "center",
              fontSize: [4, 5, 6],
              textShadow: "2px 2px 4px rgba(0,0,0,0.3)",
              maxWidth: "800px",
              mx: "auto",
              px: 3,
            }}
          >
            Parkingi przy lotniskach
          </Heading>
        </Box>
      </Box>

      <Container sx={{ px: [3, 4], py: [4, 5], maxWidth: "1200px" }}>
        <CountrySection
          title="Parkingi przy lotniskach w Polsce"
          websites={websites_Polska}
        />

        <CountrySection
          title="Parkingi przy lotniskach w Czechach"
          websites={websites_Czechy}
        />

        <CountrySection
          title="Parkingi przy lotniskach w Hiszpanii"
          websites={websites_Hiszpania}
        />

        <CountrySection
          title="Parkingi przy lotniskach w Niemczech"
          websites={websites_Niemcy}
        />
      </Container>

      <h2>Parkingi przy lotnisku {process.env.GATSBY_PARKING_SIMPLE_URL}</h2>
      <Paragraph>
        🚗✈️ Parkingi przy lotnisku {process.env.GATSBY_PARKING_SIMPLE_URL}–
        wygodne i bezpieczne miejsce dla Twojego auta! Szukasz sprawdzonego
        parkingu przy lotnisku? Na {process.env.GATSBY_PARKING_SIMPLE_URL}
        znajdziesz najlepsze parkingi w pobliżu lotniska, które zapewnią Ci
        komfort i bezpieczeństwo podczas podróży. gwarantowane miejsce!
        Zarezerwuj parking już teraz i rozpocznij podróż bez stresu!
      </Paragraph>
    </Layout>
  )
}

export default ListaLotniskPage

export const Head = () => (
  <>
    <title>Parkingi przy lotniskach w Polsce | Rezerwacja online</title>
    <meta
      name="description"
      content="Parkingi przy lotniskach w Polsce i Europie. Znajdź parking przy swoim lotnisku i zarezerwuj online. "
    />
  </>
)
